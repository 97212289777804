import * as React from 'react';
import { Box, ResponsiveContext } from 'grommet';

const Price = () => {
  let appDirection = 'column';
  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        if (size !== 'xsmall' && size !== 'small') {
          appDirection = 'row';
        } else {
          appDirection = 'column';
        }
        return (
          <>
            <Box
              border="all"
              style={{
                padding: '1rem',
                marginBottom: '4rem',
              }}
            >
              <Box style={{ marginBottom: '1.5rem' }}>
                <h2 style={{ fontSize: '2rem', marginBottom: '0rem' }}>
                  {'＜ご利用料金（トライアル特価）＞'}
                </h2>
              </Box>
              <Box>
                <div style={{ paddingLeft: '20px', paddingRight: '20px', marginBottom: '2rem' }}>
                  <h3 style={{ fontSize: '1.3rem', fontWeight: 'initial', marginBottom: '0rem' }}>
                    {'＜VRゴーグル端末（'}
                    <a
                      href="https://www.vive.com/jp/product/vive-focus3/overview/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      VIVE FOCUS 3
                    </a>
                    {'）(*1)＞'}
                  </h3>
                  <table
                    border="1"
                    rules="all"
                    style={{ borderColor: '#cccccc', borderWidth: 'inherit', width: 'auto' }}
                    className="tbl-fix-vr"
                  >
                    <thead>
                      <tr>
                        <th
                          style={{
                            width: 'auto',
                            whiteSpace: 'nowrap',
                            textAlign: 'center',
                            backgroundImage:
                              'linear-gradient(to top left,transparent, transparent 49%,#dddddd 49%,#dddddd 51%,   /* 線の太さ */transparent 51%, transparent)',
                          }}
                        ></th>
                        <th
                          style={{
                            width: 'auto',
                            whiteSpace: 'nowrap',
                            textAlign: 'center',
                          }}
                        >
                          価格
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          style={{
                            whiteSpace: 'nowrap',
                            textAlign: 'center',
                            backgroundColor: '#ffffff',
                          }}
                        >
                          <div>
                            <span>{'お客様所有のVRゴーグル(*1)を'}</span>
                          </div>
                          <div>
                            <span>{'ご使用の場合'}</span>
                          </div>
                        </td>
                        <td
                          style={{
                            whiteSpace: 'nowrap',
                            textAlign: 'center',
                            backgroundColor: '#ffffff',
                            padding: '0.5rem 3rem',
                          }}
                        >
                          <div>{'12,000円/台(初期設定費用) (*2)(*4)'}</div>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            whiteSpace: 'nowrap',
                            textAlign: 'center',
                            backgroundColor: '#ffffff',
                            padding: '0.5rem 3rem',
                          }}
                        >
                          <span>{'端末レンタルの場合(*3)'}</span>
                        </td>
                        <td
                          style={{
                            whiteSpace: 'nowrap',
                            textAlign: 'center',
                            backgroundColor: '#ffffff',
                          }}
                        >
                          <div>{'10,000円/台・月(*4)'}</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div>
                    <div>{'(*1) HTC社製 VIVE FOCUS ３を専用端末としております。'}</div>
                    <div style={{ paddingLeft: '1rem' }}>
                      {'ご使用にあたっては、PCやインターネット接続等は不要です。（完全独立式）'}
                    </div>
                    <div style={{ marginTop: '0.5rem' }}>
                      {
                        '(*2) VRゴーグル端末を弊社(愛知県刈谷市)まで送付いただき、初期設定を行います。'
                      }
                    </div>
                    <div style={{ paddingLeft: '1rem' }}>
                      {'初期設定費用は初回ご契約時にのみ発生いたします。'}
                    </div>
                    <div style={{ paddingLeft: '1rem' }}>
                      {
                        '初回契約期間を終え、再度ご契約をされる場合は、初期設定費用はかかりません。(再契約時点で再度端末を弊社まで送付いただく必要はございます)'
                      }
                    </div>
                    <div style={{ marginTop: '0.5rem' }}>
                      {
                        '(*3)レンタル端末の紛失、落下、浸水などによる故障が発生した場合、修理代相当額をご負担いただきます。'
                      }
                    </div>
                    <div style={{ paddingLeft: '1rem' }}>
                      {'ご負担額の上限は、買い換え代相当額約13万円となります。'}
                    </div>
                    <div style={{ marginTop: '0.5rem' }}>
                      {'(*4) 端末郵送費のご負担はお客様にお願いをしております。'}
                    </div>
                  </div>
                </div>
                <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                  <h3 style={{ fontSize: '1.3rem', fontWeight: 'initial', marginBottom: '0.5rem' }}>
                    {'＜コンテンツ使用料＞'}
                  </h3>
                  <div>
                    {'各プラン期間内で上記３コンテンツをプレイし放題（料金はご契約時にお支払い）'}
                  </div>
                  <Box direction={appDirection}>
                    <Box basis="1/2">
                      <table
                        border="1"
                        rules="all"
                        className="tbl-fix-vr"
                        style={{ borderColor: '#cccccc', borderWidth: 'inherit', width: 'auto' }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                whiteSpace: 'nowrap',
                                textAlign: 'center',
                                backgroundImage:
                                  'linear-gradient(to top left,transparent, transparent 49%,#dddddd 49%,#dddddd 51%,   /* 線の太さ */transparent 51%, transparent)',
                              }}
                            ></th>
                            <th
                              style={{
                                whiteSpace: 'nowrap',
                                textAlign: 'center',
                              }}
                            >
                              価格
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style={{ backgroundColor: '#ffffff' }}>
                              <span>1ヶ月プラン</span>
                            </td>
                            <td
                              style={{
                                textAlign: 'right',
                                whiteSpace: 'nowrap',
                                backgroundColor: '#ffffff',
                                padding: '0.5rem 0.5rem 0.5rem 3rem',
                              }}
                            >
                              <span>20,000円/台</span>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ backgroundColor: '#ffffff' }}>
                              <span>3ヶ月プラン</span>
                            </td>
                            <td
                              style={{
                                textAlign: 'right',
                                whiteSpace: 'nowrap',
                                backgroundColor: '#ffffff',
                                padding: '0.5rem 0.5rem 0.5rem 3rem',
                              }}
                            >
                              <span>55,000円/台</span>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ backgroundColor: '#ffffff' }}>
                              <span>6ヶ月プラン</span>
                            </td>
                            <td
                              style={{
                                textAlign: 'right',
                                whiteSpace: 'nowrap',
                                backgroundColor: '#ffffff',
                                padding: '0.5rem 0.5rem 0.5rem 3rem',
                              }}
                            >
                              <span>100,000円/台</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Box>
                    <Box basis="1/2">
                      <Box
                        style={{
                          margin: '1rem',
                          padding: '0.3rem',
                          fontSize: '0.9rem',
                          border: '1px solid #cccccc',
                          height: '100%',
                        }}
                        justify="center"
                      >
                        <div>
                          {
                            '端末を現場の休憩所や事務所等に設置し、お一人ずつ隙間時間に受講いただくことを想定しています。'
                          }
                        </div>
                        <div>
                          {
                            '1回の休憩(午前、昼、午後)毎に、1名ずつ受講される場合、1ヶ月間で約60名が受講できる計算となります。'
                          }
                        </div>
                        <div>
                          {'拠点ごとのご利用人数と期間に応じ、必要な台数をご検討ください。'}
                        </div>
                      </Box>
                    </Box>
                  </Box>
                </div>
              </Box>
            </Box>
          </>
        );
      }}
    </ResponsiveContext.Consumer>
  );
};

export default Price;
