import * as React from 'react';
import Layout from '../components/layout/layout';
import { Helmet } from 'react-helmet';
import Seo from '../components/seo';
import VRSafetyEducation from '../components/VRSafetyEducation/VRSafetyEducation';

const VRSolutionPage = () => (
  <Layout>
    <Helmet>
      <meta name="robots" content="noindex" />
      <meta name="googlebot" content="noindex" />
    </Helmet>
    <Seo title="VRソリューション" />
    <VRSafetyEducation />
  </Layout>
);

export default VRSolutionPage;
