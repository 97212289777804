import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Box, ResponsiveContext } from 'grommet';
import './VRSafetyEducation.css';

const Footer = () => {
  let appDirection = 'column';
  let pageMaxWidth = '80%';

  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        if (size !== 'xsmall' && size !== 'small') {
          appDirection = 'row';
          pageMaxWidth = '80%';
        } else {
          appDirection = 'column';
          pageMaxWidth = '100%';
        }
        return (
          <>
            <Box style={{ marginBottom: '2rem', maxWidth: pageMaxWidth, margin: '0 auto' }}>
              <Box direction="row" justify="center" align="center" style={{ marginBottom: '1rem' }}>
                <h2 style={{ fontSize: '2.5rem', lineHeight: '3.5rem', textAlign: 'center' }}>
                  VR安全教育についてもっと知る
                </h2>
              </Box>
              <Box
                direction={appDirection}
                style={{ marginBottom: '1.5rem', padding: '0 24px 0 24px' }}
              >
                <Box
                  height={{ min: '100px' }}
                  style={{ flexBasis: '40%' }}
                  align="center"
                  justify="center"
                >
                  <a
                    href="/%e3%81%8a%e5%95%8f%e3%81%84%e5%90%88%e3%82%8f%e3%81%9b/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StaticImage
                      objectFit="contain"
                      src="../../icons/baseline_question_answer_black_48dp.png"
                      formats={['auto', 'webp', 'avif']}
                      alt={'お問い合わせ'}
                      width={250}
                      height={250}
                      imgClassName="ofi"
                      imgStyle={{ width: '100%' }}
                    />
                  </a>
                </Box>
                <Box style={{ flexBasis: '60%', height: '250px' }} justify="center">
                  <span style={{ fontSize: '1.5rem', lineHeight: '2rem' }}>
                    {'お申込み、お問い合わせは「'}
                    <a
                      href="/%e3%81%8a%e5%95%8f%e3%81%84%e5%90%88%e3%82%8f%e3%81%9b/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>お問い合わせフォーム</span>
                    </a>
                    {'（対象アプリはその他全般を選択）｣にてご連絡ください。'}
                  </span>
                </Box>
              </Box>
              <hr />
              <Box style={{ marginBottom: '1rem' }}>
                <h2 style={{ fontSize: '2.5rem', lineHeight: '3.5rem', textAlign: 'center' }}>
                  FAQ
                </h2>
              </Box>
              <Box>
                <Box className="faq-card">
                  <Box direction={appDirection}>
                    <span className="faq-q">
                      {'Q1. '}
                      <span className="faq-q-text">申し込みはどのように行いますか？</span>
                    </span>
                  </Box>
                  <Box direction={appDirection} style={{ marginTop: '1rem' }}>
                    <span className="faq-a-text">
                      上記のお問い合わせフォームよりご連絡ください。
                    </span>
                  </Box>
                </Box>
                <Box className="faq-card">
                  <Box direction={appDirection}>
                    <span className="faq-q">
                      {'Q2. '}
                      <span className="faq-q-text">
                        利用期限以降も継続利用したい場合はどうすれば良いですか？
                      </span>
                    </span>
                  </Box>
                  <Box direction={appDirection} style={{ marginTop: '1rem' }}>
                    <span className="faq-a-text">
                      延長料金の設定はございませんので、再度プラン申込をいただくこととなります。
                    </span>
                  </Box>
                </Box>
                <Box className="faq-card">
                  <Box direction={appDirection}>
                    <span className="faq-q">
                      {'Q3. '}
                      <span className="faq-q-text">利用期限前に解約はできますか？</span>
                    </span>
                  </Box>
                  <Box direction={appDirection} style={{ marginTop: '1rem' }}>
                    <span className="faq-a-text">
                      途中解約及び返金対応はいたしかねますのでご了承ください。
                    </span>
                  </Box>
                </Box>
                <Box className="faq-card">
                  <Box direction={appDirection}>
                    <span className="faq-q">
                      {'Q4. '}
                      <span className="faq-q-text">
                        機器が故障してしまった場合はどうすればいいですか？
                      </span>
                    </span>
                  </Box>
                  <Box direction={appDirection} style={{ marginTop: '1rem' }}>
                    <span className="faq-a-text">
                      端末をお客様が手配された場合は、ご購入された販売店へお問い合わせください。レンタルの場合は、ご契約後にご案内するサポートデスクまでe-mailにてご連絡ください。
                    </span>
                  </Box>
                </Box>
                <Box className="faq-card">
                  <Box direction={appDirection}>
                    <span className="faq-q">
                      {'Q5. '}
                      <span className="faq-q-text">体験に適した場所はありますか？</span>
                    </span>
                  </Box>
                  <Box direction={appDirection} style={{ marginTop: '1rem' }}>
                    <span className="faq-a-text">
                      屋内であればどこでも体験いただけますが、安全のためイスに座ってのご利用をお薦めいたします。なお、電子機器のため、屋外での使用は不可です。
                    </span>
                  </Box>
                </Box>
                <Box className="faq-card">
                  <Box direction={appDirection}>
                    <span className="faq-q">
                      {'Q6. '}
                      <span className="faq-q-text">他のVRヘッドセットでは利用できませんか？</span>
                    </span>
                  </Box>
                  <Box direction={appDirection} style={{ marginTop: '1rem' }}>
                    <span className="faq-a-text">
                      ご利用できません。VIVE FOCUS 3を指定端末としております。
                    </span>
                  </Box>
                </Box>
                <Box className="faq-card">
                  <Box direction={appDirection}>
                    <span className="faq-q">
                      {'Q7. '}
                      <span className="faq-q-text">体験中、気分が悪くなることがありますか？</span>
                    </span>
                  </Box>
                  <Box direction={appDirection} style={{ marginTop: '1rem' }}>
                    <span className="faq-a-text">
                      個人差がありますが、お客様によっては、気分を悪くされる場合もございます。当日の体調によるところもございますので、無理をなさらず、体験を中止してください。
                    </span>
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        );
      }}
    </ResponsiveContext.Consumer>
  );
};

export default Footer;
