import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Box, ResponsiveContext } from 'grommet';
import './VRSafetyEducation.css';

const Top = () => {
  let appDirection = 'column';
  let headerTextMarginWidth = null;
  let textPaddingRight = null;

  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        if (size !== 'xsmall' && size !== 'small') {
          appDirection = 'row';
          headerTextMarginWidth = '9rem';
          textPaddingRight = '40px';
        } else {
          appDirection = 'column';
          headerTextMarginWidth = '1rem';
          textPaddingRight = null;
        }
        return (
          <>
            <Box style={{ marginTop: '2rem', marginBottom: '2.5rem' }}>
              <Box direction="row" justify="center" align="center" style={{ marginBottom: '2rem' }}>
                <StaticImage
                  objectFit="contain"
                  src="../../icons/vrAccident.png"
                  formats={['auto', 'webp', 'avif']}
                  alt={'VR安全教育'}
                  width={60}
                  height={40}
                  imgClassName="ofi"
                />
                <h1 style={{ fontSize: '2rem', marginLeft: '0.5rem', marginBottom: '0rem' }}>
                  VR安全教育
                </h1>
              </Box>
              <Box align="center">
                <Box align="center" style={{ marginBottom: '2rem' }}>
                  <h2 style={{ textAlign: 'center', fontSize: '2.5rem', lineHeight: '3.5rem' }}>
                    全員で安全行動を実践し、安心して働ける職場へ
                  </h2>
                  <span style={{ textAlign: 'center', fontSize: '1.2rem' }}>
                    ～過去の災害を、当事者視点で追体験し、危険予知、安全行動を学ぶ～
                  </span>
                </Box>
                <Box align="center">
                  <StaticImage
                    objectFit="contain"
                    src="../../images/VRSolution/VRImage.png"
                    formats={['auto', 'webp', 'avif']}
                    alt={'VR安全教育'}
                    height={400}
                    imgClassName="ofi"
                    imgStyle={{ width: '100%' }}
                  />
                </Box>
              </Box>
            </Box>
            <Box style={{ marginBottom: '2.5rem' }}>
              <Box
                direction="row"
                justify="center"
                align="center"
                style={{ marginBottom: '1.5rem' }}
              >
                <h2 style={{ fontSize: '2rem', marginBottom: '0rem' }}>
                  β版トライアルのご提供を開始します
                </h2>
              </Box>
              <Box>
                <Box
                  style={{
                    fontSize: '1.2rem',
                    lineHeight: '1.4rem',
                    marginBottom: '1rem',
                    textAlign: 'center',
                  }}
                >
                  <p>VR安全教育による安心・安全な現場作りに</p>
                  <p>
                    ご興味ある方は、募集期間中に本ページ最下部の問い合わせリンクよりご連絡ください。
                  </p>
                </Box>
                <Box
                  style={{
                    fontSize: '1.2rem',
                    lineHeight: '1.4rem',
                    marginBottom: '2rem',
                    textAlign: 'center',
                    fontWeight: 'bold',
                  }}
                >
                  <p>＜募集期間（予定）：４月１日～９月３０日＞</p>
                </Box>
              </Box>
            </Box>

            <Box style={{ marginBottom: '2.5rem' }}>
              <Box
                direction="row"
                justify="center"
                align="center"
                style={{ marginBottom: '1.5rem' }}
              >
                <h2 style={{ fontSize: '2rem', marginBottom: '1rem' }}>本サービスの特長</h2>
              </Box>
              <Box>
                <Box
                  style={{
                    fontSize: '1.6rem',
                    lineHeight: '1.8rem',
                    marginBottom: '2rem',
                    marginLeft: headerTextMarginWidth,
                    marginRight: headerTextMarginWidth,
                  }}
                >
                  <ol style={{ fontWeight: 'bold' }}>
                    <li type="1" style={{ marginBottom: '2rem' }}>
                      <div>物流現場を舞台にした３つのシナリオを体験</div>
                      <ul class="list_num_parentheses" style={{ fontWeight: 'initial' }}>
                        <li class="list_num_parentheses_li_1">
                          <div>
                            過去の実例に基づく事故体験とその危険因子を全体を見渡して特定する2段重ねのトレーニング
                          </div>
                        </li>
                        <li class="list_num_parentheses_li_2">
                          <div>模範的なフォークリフト安全運転動作を体験</div>
                        </li>
                      </ul>
                    </li>
                    <li type="1" style={{ marginBottom: '2rem' }}>
                      <div>安全教育にまとまった時間を確保できないお客様に</div>
                    </li>
                    <li type="1" style={{ marginBottom: '2rem' }}>
                      <div>
                        作業スタッフお一人ずつにカスタマイズした安全教育の実現に向けた第一歩として
                      </div>
                    </li>
                    <li type="1">
                      <div>気軽に始められるプランをご用意</div>
                      <ul style={{ fontWeight: 'initial', listStyle: 'none' }}>
                        <li>
                          お気軽にご利用いただけるよう、VRゴーグル端末のレンタルや1ヵ月プランをご用意しております
                        </li>
                      </ul>
                    </li>
                  </ol>
                </Box>
              </Box>
            </Box>
            <Box
              style={{
                marginBottom: '2.5rem',
              }}
            >
              <h2 style={{ fontSize: '2rem', marginBottom: '1rem' }}>コンテンツ</h2>
              <Box
                border="all"
                style={{ marginBottom: '1rem', paddingLeft: '1rem', paddingRight: '1rem' }}
              >
                <h3 style={{ margin: '0.5rem', fontSize: '1.6rem', lineHeight: '3.5rem' }}>
                  <div style={{ fontWeight: 'initial' }}>シナリオ①</div>
                  <div>『ピッキング漏れしていた品物を慌てて取りに行く歩行者』</div>
                </h3>
                <Box pad="medium" direction={appDirection} style={{ marginBottom: '1.5rem' }}>
                  <Box
                    basis="1/2"
                    height={{ min: '100px' }}
                    style={{ paddingRight: textPaddingRight }}
                    justify="center"
                  >
                    <div className="scenario-text">
                      {'フォークリフト複数台が走り回る出荷場とその周辺が舞台です。'}
                    </div>
                    <div className="scenario-text">
                      {'歩行者(貴方)はピッキング漏れに気づき、慌ててその品物を取りにいきます。'}
                    </div>
                    <div className="scenario-text" style={{ marginBottom: '1.2rem' }}>
                      {'慌てて、歩行帯から外れて進んでいった先に起こる事故を体験します。'}
                    </div>
                    <div className="scenario-text">
                      {'答え合わせ編は、事故発生時の現場全体を見渡しながら、危険因子を探します。'}
                    </div>
                    <div className="scenario-text">
                      {
                        '事故当事者となった人の行動だけでなく、そもそも現場環境や２Sの状況に問題はなかったのか、深く考えるトレーニングです。'
                      }
                    </div>
                  </Box>
                  <Box
                    basis="1/2"
                    flex="shrink"
                    height={{ min: '100px' }}
                    direction="row"
                    justify="center"
                  >
                    <Box>
                      <StaticImage
                        objectFit="contain"
                        src="../../images/VRSolution/scenario-1.png"
                        formats={['auto', 'webp', 'avif']}
                        alt={'シナリオ①'}
                        imgClassName="ofi"
                        height={{ min: '100px' }}
                        imgStyle={{ width: '100%' }}
                      />
                      <div style={{ marginTop: '0.8rem', textAlign: 'center' }}>
                        {'<体験時間目安：約5分>'}
                      </div>
                      <div style={{ marginTop: '2rem' }}>
                        {
                          '※このシナリオは実際に発生した過去の災害事例を参考にし、危険予知の勘所を体験者が気付けるように創作したものです。'
                        }
                      </div>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                border="all"
                style={{ marginBottom: '1rem', paddingLeft: '1rem', paddingRight: '1rem' }}
              >
                <h3 style={{ margin: '0.5rem', fontSize: '1.6rem', lineHeight: '3.5rem' }}>
                  <div style={{ fontWeight: 'initial' }}>シナリオ②</div>
                  <div>『フォークリフトの落下物を片付けようとする歩行者』</div>
                </h3>
                <Box pad="medium" direction={appDirection} style={{ marginBottom: '1.5rem' }}>
                  <Box
                    basis="1/2"
                    height={{ min: '100px' }}
                    style={{ paddingRight: textPaddingRight }}
                    justify="center"
                  >
                    <div className="scenario-text">
                      {'フォークリフトが荷役作業をする木材製品置き場が舞台です。'}
                    </div>
                    <div className="scenario-text" style={{ marginBottom: '1.2rem' }}>
                      {
                        '歩行者(貴方)は近くで作業するフォークリフトがりん木を落下させたため、それを取り除くためにフォークリフトに近づき、事故に巻き込まれる体験をします。'
                      }
                    </div>
                    <div className="scenario-text">
                      {'答え合わせ編は、事故発生時の現場全体を見渡しながら、危険因子を探します。'}
                    </div>
                    <div className="scenario-text">
                      {
                        '事故当事者となるフォークリフトの荷役作業のやり方をはじめ、そもそも現場環境に問題はなかったのか、深く考えるトレーニングです。'
                      }
                    </div>
                  </Box>
                  <Box
                    basis="1/2"
                    flex="shrink"
                    height={{ min: '100px' }}
                    direction="row"
                    justify="center"
                  >
                    <Box>
                      <StaticImage
                        objectFit="contain"
                        src="../../images/VRSolution/scenario-2.png"
                        formats={['auto', 'webp', 'avif']}
                        alt={'シナリオ②'}
                        imgClassName="ofi"
                        height={{ min: '100px' }}
                        imgStyle={{ width: '100%' }}
                      />
                      <div style={{ marginTop: '0.8rem', textAlign: 'center' }}>
                        {'<体験時間目安：約5分半>'}
                      </div>
                      <div style={{ marginTop: '2rem' }}>
                        {
                          '※このシナリオは実際に発生した過去の災害事例を参考にして創作したものです。'
                        }
                      </div>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                border="all"
                style={{ marginBottom: '1rem', paddingLeft: '1rem', paddingRight: '1rem' }}
              >
                <h3 style={{ margin: '0.5rem', fontSize: '1.6rem', lineHeight: '3.5rem' }}>
                  <div style={{ fontWeight: 'initial' }}>シナリオ③</div>
                  <div>『パレット運搬作業を行うフォークリフト運転手』</div>
                </h3>
                <Box pad="medium" direction={appDirection} style={{ marginBottom: '1.5rem' }}>
                  <Box
                    basis="1/2"
                    height={{ min: '100px' }}
                    style={{ paddingRight: textPaddingRight }}
                    justify="center"
                  >
                    <div className="scenario-text">
                      {'多くの作業者、フォークリフトが動き回る工場が舞台です。'}
                    </div>
                    <div className="scenario-text" style={{ marginBottom: '1.2rem' }}>
                      {
                        'フォークリフト運転手(貴方)は完成品を取り、出荷エリアに待つトラックまで荷物を運搬します。'
                      }
                    </div>
                    <div className="scenario-text" style={{ marginBottom: '1.2rem' }}>
                      {
                        'コントローラー2本で、左右指差し呼称、フォーク上昇/下降、チルト前傾/後傾、クラクション、ウインカーを操作しながら、模範的なフォークリフト安全運転動作を体験します。'
                      }
                    </div>
                    <div className="scenario-text">
                      {
                        '動作ガイダンスあり・なしのモード切り替えができます。ガイダンスなしでも安全動作が自然とできるまで、繰り返しトレーニングしてみてください。'
                      }
                    </div>
                  </Box>
                  <Box
                    basis="1/2"
                    flex="shrink"
                    height={{ min: '100px' }}
                    direction="row"
                    justify="center"
                  >
                    <Box>
                      <StaticImage
                        objectFit="contain"
                        src="../../images/VRSolution/scenario-3.png"
                        formats={['auto', 'webp', 'avif']}
                        alt={'シナリオ③'}
                        imgClassName="ofi"
                        height={{ min: '100px' }}
                        imgStyle={{ width: '100%' }}
                      />
                      <div style={{ marginTop: '0.8rem', textAlign: 'center' }}>
                        {'<体験時間目安：約7分>'}
                      </div>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        );
      }}
    </ResponsiveContext.Consumer>
  );
};

export default Top;
