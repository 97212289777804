import * as React from 'react';
import Top from './top';
import Price from './price';
import Footer from './footer';

const VRSafetyEducation = () => {
  return (
    <>
      <Top />
      <Price />
      <Footer />
    </>
  );
};

export default VRSafetyEducation;
